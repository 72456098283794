import React, { Component } from 'react'
import sponsorShipPackage from './../../../MAC Formula Electric - Sponsorship package-2024-2025.pdf';
import './SponsorHero.css'
import Button from '../../button/Button';




export class SponsorHero extends Component {
  
  render() {
    let sponsorshipPackageLink = window.innerWidth > 600 ?
                <a href={sponsorShipPackage} 
                    style={{ textDecoration:'none', paddingLeft:'10px'}}
                    target="_blank" rel="noopener noreferrer">
                    <Button className="long-button" text="Our Sponsorship Package"/>
                </a> : <div/>

    let donateLink = window.innerWidth > 600 ?
    <a href={"https://alumni.mcmaster.ca/s/1439/ifundmac/project.aspx?sid=1439&gid=1&pgid=12563&cid=22502&ecid=22502&crid=0&calpgid=3304&calcid=5363"} 
        style={{ textDecoration:'none', paddingLeft:'10px'}}
        target="_blank" rel="noopener noreferrer">
        <Button text="Donate"/>
    </a> : <div/>

    return (
      <div className="hero-explorer-container">
        <h1 className="hero-type-heading"><span>How Your Support Drives Our Team Forward</span></h1>
        <p className="hero-type-text"><span></span>Our team's technical and competitive success is made possible through the support of organizations
                        and individuals with shared values. With the support of the organizations listed on this page we are
                        able to foster an environment for our members that enables them to learn and grow.</p>
        <div className="hero-button-container">
          {sponsorshipPackageLink}/
          {donateLink}/
        </div>
        <hr className="divider" />
      </div>
    )
  }
}

export default SponsorHero
