import React, { Component } from "react";

import "./SponsorExplorer.css";





// import YourLogoHere from './../../../img/sponsor-logos/your-logo-here.png';
import MacEngLogo from "./../../../img/sponsor-logos/mac-eng-logo.png";
import PetroCanadaLogo from "../../../img/sponsor-logos/Petro_canada_logo.svg";
import MTEConsultantsLogo from "../../../img/sponsor-logos/mte-consultants-logo.jpg";
//Gold Sponsors
import DanaLogo from "../../../img/sponsor-logos/dana-logo.png";
import BEXLogo from "../../../img/sponsor-logos/bex-logo.png";
import EatonLogo from "../../../img/sponsor-logos/eaton-Logo.png";
import LemoLogo from "../../../img/sponsor-logos/LEMO.png";
import KISSoftLogo from "../../../img/sponsor-logos/kissoft-logo.jpg";
import MagnaLogo from "../../../img/sponsor-logos/magna-logo.png";
import MgChemLogo from "../../../img/sponsor-logos/mg-chemicals-logo.webp"; 
import PCBLLogo from "../../../img/sponsor-logos/PCBL-Logo_High.png";//pcbl
import SpeedgoatLogo from "../../../img/sponsor-logos/Speedgoat_logo_v1_transparent.png"; 
import TeslaLogo from "../../../img/sponsor-logos/tesla-logo.png";//tesla
import VintonLogo from "../../../img/sponsor-logos/vinton-logo.png";//vinton
//Silver Sponsors
import MESLogo from "./../../../img/sponsor-logos/mes-logo.svg";
import SolidworksLogo from "./../../../img/sponsor-logos/solidworks-logo.svg";
import AltiumLogo from "./../../../img/sponsor-logos/altium-logo.png";
import AnsysLogo from "./../../../img/sponsor-logos/ansys-logo.webp";
import HaasLogo from "./../../../img/sponsor-logos/haas-logo.svg";
import GoldPheonixLogo from "./../../../img/sponsor-logos/gold-pheonix-logo.png";
import MolexLogo from "./../../../img/sponsor-logos/molex-seeklogo.png";
import NtopLogo from "./../../../img/sponsor-logos/ntop-logo.png";
import VIGrade from "./../../../img/sponsor-logos/vigrade-logo.png"
//Bronze Sponsors
import KvaserLogo from "./../../../img/sponsor-logos/kvaser-logo.png";
import ADILogo from "./../../../img/sponsor-logos/adi-logo.png";
import CBNDTLogo from "./../../../img/sponsor-logos/CBNDT-logo.png";
import BorgesonLogo from "./../../../img/sponsor-logos/borgeson-logo.jpg";
import CalspanLogo from "./../../../img/sponsor-logos/calspan-logo.png";
import ProtocaseLogo from "./../../../img/sponsor-logos/protocase-logo.png";




export class SponsorExplorer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: [
                {
          id: 1,
          name: "Faculty of Engineering, McMaster University",
          websiteLink: "https://www.eng.mcmaster.ca/",
          img: MacEngLogo,
          description:
            "The McMaster Faculty of Engineering is known for innovative educational programming and for its research and engagement with industry and community. Focusing on Problem-Based Learning, the Faculty builds strong foundations for undergraduate and graduate students in their professional careers.",
          color: "rgb(253, 191, 87)",
        },
        {
          id: 2,
          name: "Petro-Canada Lubricants",
          websiteLink: "https://lubricants.petro-canada.com/",
          img: PetroCanadaLogo,
          description:
            "For over 30 years, under our Petro-Canada Lubricants brand, we have researched, developed and produced world-class advanced lubricants, specialty fluids and greases. Our products continue to perform beyond expectations in virtually every industry around the globe. ",
          color: "rgb(0,0,0)",
        },

        {
          id: 3,
          name: "McMaster Engineering Society",
          websiteLink: "https://www.macengsociety.ca/",
          img: MESLogo,
          description:
            "The McMaster Engineering Society fosters the development of well rounded undergraduate engineering students through accessible support of academics, athletics, and recreational and professional activities, while maintaining the integrity and unique traditions of the faculty society.",
          color: "rgb(167, 29, 48)",
        }
      ],
      gold: [
        {
          id: 1,
          name: "Speedgoat",
          websiteLink: "https://www.speedgoat.com/",
          img: SpeedgoatLogo,
          description:
            "PCB Libraries is a lean and agile software company that offers unparalleled software for accurate, and amazingly efficient PCB library creation and management. Their goal is to offer the highest quality and most affordable PCB design solutions available, at the lowest cost possible. They have been actively involved in development of the IPC-7351 for many years.",
          color: "rgb(0,102,179)",
        },
        {
          id: 2,
          name: "PCB Libraries",
          websiteLink: "https://www.pcblibraries.com/",
          img: PCBLLogo,
          description:
            "PCB Libraries is a lean and agile software company that offers unparalleled software for accurate, and amazingly efficient PCB library creation and management. Their goal is to offer the highest quality and most affordable PCB design solutions available, at the lowest cost possible. They have been actively involved in development of the IPC-7351 for many years.",
          color: "rgb(0,102,179)",
        },
        {
          id: 3,
          name: "Magna International",
          websiteLink: "https://www.tesla.com/en_ca",
          img: MagnaLogo,
          description:
            "",
          color: "rgb(0,0,0)",
        },
        {
          id: 4,
          name: "Tesla",
          websiteLink: "https://www.tesla.com/en_ca",
          img: TeslaLogo,
          description:
            "We’re building a world powered by solar energy, running on batteries and transported by electric vehicles. Explore the most recent impact of our products, people and supply chain.",
          color: "rgb(0,0,0)",
        },
        {
          id: 5,
          name: "LEMO",
          websiteLink: "https://www.lemo.com/en",
          img: LemoLogo,
          description:
            "",
          color: "rgb(0,0,0)",
        },
        {
          id: 6,
          name: "Dana",
          websiteLink: "https://www.dana.com/",
          img: DanaLogo,
          description:
            "Dana is a leader in the design and manufacture of highly efficient propulsion and energy-management solutions that power vehicles and machines in all mobility markets across the globe.",
          color: "rgb(28, 133, 199)",
        },
        {
          id: 7,
          name: "Eaton",
          websiteLink: "https://www.eaton.com/ca/en-gb.html",
          img: EatonLogo,
          description:
            "",
          color: "rgb(0,0,0)",
        },
        {
          id: 8,
          name: "KISSoft",
          websiteLink: "https://www.kisssoft.com/en",
          img: KISSoftLogo,
          description:
            'Our software includes internationally recognized calculation standards as well as various design and optimization options, based on the experience of our customers and development engineers - according to our slogan "Sharing Knowledge".',
          color: "rgb(16, 117, 189)",
        },
        {
          id: 9,
          name: "BEX",
          websiteLink: "https://bex.com/",
          img: BEXLogo,
          description:
            "",
          color: "rgb(208, 32, 47)",
        },
        {
          id: 10,
          name: "Vinton",
          websiteLink: "https://www.vintonprecisiontool.com/",
          img: VintonLogo,
          description:
            "PCB Libraries is a lean and agile software company that offers unparalleled software for accurate, and amazingly efficient PCB library creation and management. Their goal is to offer the highest quality and most affordable PCB design solutions available, at the lowest cost possible. They have been actively involved in development of the IPC-7351 for many years.",
          color: "rgb(0,102,179)",
        },
        {
          id: 11,
          name: "MG Chemicals",
          websiteLink: "https://mgchemicals.com/",
          img: MgChemLogo,
          description:
            "",
          color: "rgb(0,0,0)",
        },
        {
          id: 12,
          name: "Gold Phoenix PCB",
          websiteLink: "https://www.goldphoenixpcb.com/",
          img: GoldPheonixLogo,
        },
      ],
      silver: [
        {
          id: 1,
          name: "Gene Haas Foundation",
          websiteLink: "https://www.haascnc.com/content/ghf/en/home.html",
          img: HaasLogo,
        },
        {
          id: 2,
          name: "Molex",
          websiteLink: "https://www.molex.com/en-us/home",
          img: MolexLogo,
        },
        {
          id: 3,
          name: "MTE Consultants",
          websiteLink: "https://www.mte85.com/",
          img: MTEConsultantsLogo,
          description:
            "MTE Consultants is a multidisciplinary engineering firm providing services in civil and structural engineering, building and environmental science, toxicology and land surveying.",
          color: "rgb(0, 105, 144)",
        },
        {
          id: 4,
          name: "Altium",
          websiteLink: "https://education.altium.com/",
          img: AltiumLogo,
          description:
            "Industry leading PCB design software that empowers users to design boards without limits—from single PCB to multi-board systems; from low frequency to high-speed.",
          color: "rgb(166,151,106)",
        },
        {
          id: 5,
          name: "Solidworks",
          websiteLink: "https://www.solidworks.com/",
          img: SolidworksLogo,
          description:
            "SOLIDWORKS’ is a solid modelling computer-aided design (CAD) and computer-aided engineering (CAE) computer program. The program used by industry leaders provides integrated tools for every area of product development and management. Enabling enterprises to optimize processes from concepts to manufacturing.",
          color: "rgb(209,73,67)",
        },
        {
          id: 5,
          name: "VI-Grade",
          websiteLink: "https://www.vi-grade.com/",
          img: VIGrade,
          description:
            "VI-grade is the global provider of disruptive vehicle development solutions that are paving the way to developing vehicles with Zero Prototypes. Its human-centric solutions comprise industry-leading real-time simulation software, professional driving simulators and Hardware-in-the-Loop solutions that accelerate product development across the transportation industry.",
          color: "rgb(209,73,67)",
        },
        {
          id: 6,
          name: "Ansys",
          websiteLink: "https://www.ansys.com/",
          img: AnsysLogo,
        },
        {
          id: 7,
          name: "NTop",
          websiteLink: "https://www.ntop.com/",
          img: NtopLogo,
        },
      ],
      bronze: [
        {
          id: 1,
          name: "Calspan",
          websiteLink: "https://calspan.com/",
          img: CalspanLogo,
        },
        {
          id: 2,
          name: "Protocase",
          websiteLink: "https://www.protocase.com/",
          img: ProtocaseLogo,
        },
        {
          id: 3,
          name: "CB Non-Destructive Testing LTD.",
          websiteLink: "https://www.cbndt.com/CB2/main2.html",
          img: CBNDTLogo,
        },
        {
          id: 4,
          name: "Borgeson",
          websiteLink: "https://www.borgeson.com/",
          img: BorgesonLogo,
        },
        {
          id: 5,
          name: "Kvaser",
          websiteLink: "http://www.kvaser.com/",
          img: KvaserLogo,
        },
        {
          id: 6,
          name: "Analog Devices",
          websiteLink: "https://www.analog.com/en/index.html",
          img: ADILogo,
        },
      ],
    };
  }

  render() {
    return (
      <div className="sponsor-explorer-container">
        <h1 className="sponsor-type-heading">
          <span>Official EV Partners</span>
        </h1>

        <div className="sponsor-container partner">
          {this.state.partner.map((sponsor) => (
            <>
              <a
                className="sponsor"
                href={sponsor.websiteLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="sponsors-logo"
                  src={sponsor.img}
                  alt="partner-sponsor"
                />
                <div className="sponsors-text-container">
                  <h1>{sponsor.name}</h1>
                  <h2 className="sponsor-text-description">
                    {sponsor.description}
                  </h2>
                </div>
                <div
                  className="sponsor-flag-pole"
                  style={{ background: sponsor.color }}
                ></div>
                <div
                  className="sponsor-flag"
                  style={{ background: sponsor.color }}
                >
                  <h1 className="sponsor-flag-text">Visit</h1>
                </div>
              </a>
            </>
          ))}
        </div>

        <h1 className="sponsor-type-heading">
          <span>Gold Sponsors</span>
        </h1>
        <div className = "top-sponsor-container">
          <div className="reworked-sponsor-container">
          {this.state.gold.map((sponsor) => (
              <>
                <a
                  key={indexedDB}
                  className="reworked-sponsor"
                  href={sponsor.websiteLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="reworked-sponsors-logo"
                    src={sponsor.img}
                    alt="gold-sponsor"
                  />
                </a>
              </>
            ))}
          </div>
        </div>
        
        <h1 className="sponsor-type-heading">
          <span>Silver Sponsors</span>
        </h1>
        <div className = "top-sponsor-container">
          <div className="reworked-sponsor-container">
          {this.state.silver.map((sponsor) => (
              <>
                <a
                  key={indexedDB}
                  className="reworked-sponsor"
                  href={sponsor.websiteLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="reworked-sponsors-logo"
                    src={sponsor.img}
                    alt="gold-sponsor"
                  />
                </a>
              </>
            ))}
          </div>
        </div>

        <h1 className="sponsor-type-heading">
          <span>Bronze Sponsors</span>
        </h1>
        <div className = "top-sponsor-container">
          <div className="reworked-sponsor-container">
          {this.state.bronze.map((sponsor) => (
              <>
                <a
                  key={indexedDB}
                  className="reworked-sponsor"
                  href={sponsor.websiteLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="reworked-sponsors-logo"
                    src={sponsor.img}
                    alt="gold-sponsor"
                  />
                </a>
              </>
            ))}
          </div>
        </div>





        
        <div className="sponsor-container silver">
          {/*temporary silver change back to bronze if there are real sponsors*/}
          
          {/* {this.state.bronze.map(sponsor => (
                 <>
                  <a className="sponsor" href={sponsor.websiteLink} target="_blank" rel="noopener noreferrer">
                  <img className="sponsors-logo" src={sponsor.img} alt="bronze-sponsor"/>
                  <div className="sponsors-text-container">
                    <h1>{sponsor.name}</h1>
                    <h2 className="sponsor-text-description">
                        {sponsor.description}
                    </h2>
                  </div>   
                  <div className="sponsor-flag-pole" style={{background:sponsor.color}}></div>
                  <div className="sponsor-flag" style={{background:sponsor.color}}>
                      <h1 className="sponsor-flag-text">Visit
                          </h1></div> 
                  </a>  
                  </> 
              ))} */}
        </div>

        {/* <h1 className="sponsor-type-heading"><span>Bronze Sponsors</span></h1>
            <div className="sponsor-container silver">
              {this.state.bronze.map(sponsor => (
                  <>
                  <a className="sponsor" href={sponsor.websiteLink} target="_blank" rel="noopener noreferrer">
                  <img className="sponsors-logo" src={sponsor.img} alt="platinum-sponsor"/>
                  <div className="sponsors-text-container">
                    <h1>{sponsor.name}</h1>
                    <h2 className="sponsor-text-description">
                        {sponsor.description}
                    </h2>
                  </div>   
                  /!* <div className="sponsor-flag-pole" style={{background:sponsor.color}}></div> *!/
                  <div className="sponsor-flag" style={{background:sponsor.color}}>
                      <h1 className="sponsor-flag-text">Visit
                          </h1></div> 
                  </a>  
                  </> 
              ))}
            </div>

            <h1 className="sponsor-type-heading"><span>General Sponsors</span></h1>
            <div className="sponsor-container silver">
              {this.state.general.map(sponsor => (
                  <>
                  <a className="sponsor" href={sponsor.websiteLink} target="_blank" rel="noopener noreferrer">
                  <img className="sponsors-logo" src={sponsor.img} alt="platinum-sponsor"/>
                  <div className="sponsors-text-container">
                    <h1>{sponsor.name}</h1>
                    <h2 className="sponsor-text-description">
                        {sponsor.description}
                    </h2>
                  </div>   
                  /!* <div className="sponsor-flag-pole" style={{background:sponsor.color}}></div> *!/
                  <div className="sponsor-flag" style={{background:sponsor.color}}>
                      <h1 className="sponsor-flag-text">Visit
                          </h1></div> 
                  </a>  
                  </> 
              ))}
            </div> */}
      </div>
    );
  }
}

export default SponsorExplorer;
