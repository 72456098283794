import React, { Component } from 'react'
import Header from '../header/Header';
import TeamShowcase from './teamshowcase/TeamShowcase';
import PhotoSlide from '../photoslide/PhotoSlide';

import './TeamPage.css'

import openHousePhoto from './../../img/hatch/open-house.jpeg';
import Footer from '../footer/Footer';
import TeamMembers from './teammembers/TeamMembers';
import Button from '../button/Button.jsx';

export class TeamPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {

    let photoSlideLink = window.innerWidth > 600 ?
    <a href={"https://macformularacing.com/recruitment"}
    style={{ textDecoration:'none', paddingLeft:'50px'}}
    target="_blank" rel="noopener noreferrer">
    <Button text="Explore"/>
    </a> : <div/>;

    return (
      <div className="team-page-container">
        <Header />
        <TeamShowcase />
        {/* <div className="team-photo-container">
          <img className="team-photo" src={teamPhoto}></img>
        </div> */}
        
        <PhotoSlide 
                    img={openHousePhoto}
                    title="Team Recruitments"
                    text="Our team partakes in open house events throughout the year, and hosts information sessions at the beginning of the school year. Interested students can attend attend these sessions and find out if our team is what they are looking for. We will post event updates on our Facebook, Instagram, and LinkedIn. Please follow our social media channels for updates on these events!"
                    button = {photoSlideLink}
                    />
        <TeamMembers/>
        <Footer />
      </div>
    )
  }
}

export default TeamPage
