import React, { Component } from 'react'
import Header from '../header/Header';
import ShowcaseLink from '../showcaselink/ShowcaseLink';

import './SponsorsPage.css'

import hybridInHatch from "./../../img/ev5/hatch/hatch-photo-2024.JPG";

import sidePodshot from './../../img/parts/hybrid-side-pod.png';
import SponsorHero from './sponsorhero/SponsorHero';
import SponsorExplorer from './sponsorexplorer/SponsorExplorer';
import Footer from '../footer/Footer';
export class SponsorsPage extends Component {

componentDidMount() {
  window.scrollTo(0, 0)
  setTimeout(function() {
    document.getElementById("sponsorship-top-showcase").style.opacity = 1;
    document.getElementById("sponsorship-top-showcase-image").style.right = "50px";

    if(window.innerWidth>1400) {
      document.getElementById("sponsorship-top-showcase-text").style.top = "300px";
    } else {
      document.getElementById("sponsorship-top-showcase-text").style.top = "20vw";
    }

  },500);
}

  render() {
    return (
      <div>
        <Header />
        <div id="sponsorship-top-showcase">
          <img id="sponsorship-top-showcase-image" src={hybridInHatch} alt=""></img>
          <div className="sponsorship-showcase-gradient"/>
          <div id="sponsorship-top-showcase-text">
            <h1>Sponsorship and Partners</h1>
          </div>
        </div>
        <SponsorHero />
        <SponsorExplorer />
        <Footer />
      </div>
    )
  }
}

export default SponsorsPage
