import React, { Component } from 'react'

import './ContactPage.css'
import Header from '../header/Header';

import hatchGarage from './../../img/hatch/garage.jpeg'
import Footer from '../footer/Footer';

export class ContactPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
    setTimeout(function() {
      document.getElementById('contact-showcase').style.opacity = 1
    },100)
  }

  render() {

    return (
      <div className="contact-page-container">
        <Header/>
        <div id="contact-showcase">
          <img id="contact-image" src={hatchGarage} alt=""/>
          <h1 className="contact-title">Contact Us</h1>
          <div className="contact-showcase-gradient"></div>
          <div className="contact-text-container">
            <a href={"mailto:formula@mcmaster.ca"} style={{"text-decoration":"none"}}>
            <div className="contact-email-container">
              <h1 className="contact-email">formula@mcmaster.ca</h1>
            </div>
            </a>
            <div className="contact-social-media-container">

              <a href="https://www.instagram.com/macformulaelectric/?hl=en" style={{"text-decoration":"none"}}>
              <div className="social-media-row">
                <h1 className="social-media-title">Instagram: <span>macformulaelectric</span></h1>
                <div className="social-media-white-underline"/>
              </div>
              </a>

              <a href="https://www.facebook.com/macformulaelectric/" style={{"text-decoration":"none"}}>
              <div className="social-media-row">
                <h1 className="social-media-title">Facebook: <span>macformula</span></h1>
                <div className="social-media-white-underline"/>
              </div>
              </a>

              <a href="https://www.linkedin.com/company/mac-formula-electric/" style={{"text-decoration":"none"}}>
              <div className="social-media-row">
                <h1 className="social-media-title">LinkedIn: <span>MAC Formula Electric</span></h1>
                <div className="social-media-white-underline"/>
              </div>
              </a>

            </div>
            
          </div>

        </div>
        <Footer/>
      </div>
    )
  }
}

export default ContactPage