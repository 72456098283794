import React from 'react'
import './LoadingSpinner.scss';

export function LoadingSpinner () {
    return (
        <div class="loading-spinner"></div>
    )
}

export default LoadingSpinner
