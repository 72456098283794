import React, { Component } from 'react';

import './Positions.css';

export class Positions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            positionsListed: 'all',
            positions: [
                // {
                //     type: ['powertrain'],
                //     name: 'Accumulator',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'The Accumulator sub-team is responsible for the design, testing, and physical integration of the accumulator assembly: the complete package of energy storage, and mechanical and electrical regulatory components. The challenge for the Accumulator sub-team is considering how to design around the thermal and electrical performance of the selected energy storage modules to meet both our performance goals and the official rules requirements while packaging and mounting it in a way that is space and weight efficient, and easy to service. ',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'The Accumulator sub-team is looking to further strengthen its mechanical expertise. As a prospective member you can look forward to helping choose the appropriate material to ensure compliance with the rules of the housing/enclosures. You will have the chance to learn and exercise your thermodynamic and heat transfer knowledge to evaluate the cooling needs of our HV components: validating your decisions with thermal simulations and physical testing. Working with the full car assembly in SolidWorks, you will help design the packaging and mounting solutions to be ergonomic and space efficient. ',
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'We are looking for members who are dedicated, willing to learn and contribute, and passionate about their technical interests. Someone who can take directions and has strong time management, communication skills, and reliability. Experience using SolidWorks, or any equivalent CAD program is ideal, but not required. ',
                //         },
                //     ],
                //     openDate: 'August 28, 2024 at 12:00 AM EST',
                //     closeDate: 'September 4, 2024 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/Frc5hcvraV',
                // },
                // {
                //     type: ['mechanical'],
                //     name: 'Chassis',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'On the chassis sub-team, we develop the base or frame of the vehicle. We design a bespoke carbon fiber composite monocoque every year plus all the other added safety components to compete at sanctioned Formula SAE events. This Includes Roll hoops, Jack Bar, Firewall, Access hatches and all the materials testing to prove stiffness and strength equivalency.',
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'This sub-team is open to both upper- and lower-level students. All students are involved in both the designing and testing/adjusting of carbon fiber panels, firewall, roll hoops, and other car elements. Upper year students will have a focus on simulation and layup design theory while lower year students will help with CAD, Drawings, and manufacturing. The expectations above are only a rough outline, not a hard rule of what team members can or will be doing throughout the year.',
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'Previous CAD and CAE experience is preferred but not required. There will be opportunities for members to learn throughout the year on the sub-team. Previous knowledge of racing cars and/or materials testing, and categorization will also be useful but not required. Members can expect to share tasks with other members, which will require them to collaborate well and demonstrate active listening. Members are expected to learn, work hard, as well as being reliable and accountable.'
                //         },
                //     ],
                //     openDate: 'August 28, 2024 at 12:00 AM EST',
                //     closeDate: 'September 4, 2024 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/aEKCZm471R',
                // },
                // {
                //     type: ['mechanical'],
                //     name: 'Aerodynamics',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'The Aerodynamics team is responsible for the design, analysis, testing, and validation of the vehicle\'s aerodynamic package. By applying engineering principles and models, we optimize airflow around the vehicle to achieve determined performance objectives. '
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'Members will be involved in the analysis and design of an aerodynamic device for the vehicle, collaborating in small teams to research and validate design requirements. They will model designs in SolidWorks, perform CFD simulations in Ansys, and iteratively refine the design, collaborating with the rest of the sub-team to consider the overall vehicle behaviour. Additional areas of involvement include research, FEA analysis, dynamic modelling, and participation in manufacturing and assembly. '
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'We are looking for members who are genuinely interested in learning about aerodynamics and working through new concepts. Being communicative, reliable, enthusiastic, and dedicated to the team’s success is a priority. Having previous experience in CAD, CFD, fluid dynamics, and MATLAB is a benefit. '
                //         },
                //     ],
                //     openDate: 'August 28, 2024 at 12:00 AM EST',
                //     closeDate: 'September 4, 2024 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/RFzUsGndRR',
                // },
                // {
                //     type: ['mechanical'],
                //     name: 'Brakes and Pedal Tray',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'The Brakes and Pedal Tray sub-team is responsible for the design, analysis, manufacturing, testing and validation of the vehicles braking and throttle systems. We create designs following the specifications through FSAE rules and our team goals, validate through FEA, and ultimately manufacture and deliver a system to stop the car over the minimum possible distance. ' 
                                
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'Our team is seeking both upper- and lower-year students, who will each be responsible entirely for a subcomponent of the brakes system. This involves creating and iterating through the design, modelling them in SolidWorks, validating through FEA and finally manufacturing the subcomponent. Additionally, some members may be asked to perform calculations and analyses on the thermodynamic characteristics of the brake rotor and braking system. You will also be optimizing the designs stiffness and strength to minimize the weight of the brakes system. Finally, you will collaborate with other Brakes members and members of other sub-teams to integrate the braking system into the vehicle overall. ' 
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'We are looking for members who are eager to learn about and contribute to the brakes system and find innovative ways to minimize weight. Reliability, dedication, communication and interpersonal skills are an expectation as well as a foundational knowledge of 3D modelling and CAD. Any previous experiences with FEA or thermodynamic simulations are assets. Additionally, any previous hands-on experience is a great benefit. ' 
                //         },
                //     ],
                //     openDate: 'August 28, 2024 at 12:00 AM EST',
                //     closeDate: 'September 4, 2024 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/JjA0Hh2EkK',
                // },
                // {
                //     type: ['buisness'],
                //     name: 'Business ',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'The Business sub-team of MAC Formula Electric is responsible for the overall marketing and sponsorship of the team. Marketing and sponsorship activities include but is not limited to, brand promotion, content generation, as well as sponsorship opportunities.' 
                                
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'Because the Business sub-team plays a key role in enabling MAC Formula Electric’s technical endeavours, we are seeking innovative students that are prepared to embrace the challenges that are associated with the following activities: \n' + 
                //                 'Recommended/Preferred Skills: \n' +
                //                 '\n' +
                //                 '   - Branding and Marketing: Coordinate with the Team Principal, Team Lead, and fellow members of the sub-team to continuously enhance our branding guidelines, develop creative marketing project ideas and produce team apparel design concepts  \n' +
                //                 '\n' + 
                //                 '   - Social Media: Oversee the team’s social media presence by creating social media calendars that align with internal business goals and sponsorship obligations  \n' +
                //                 '\n' + 
                //                 '   - Content Creation: Generate media as necessary for sponsorship outreach and marketing initiatives, with a focus on social media posts and website management   \n' +
                //                 '\n'  + 
                //                 '   - Sponsorship and Stakeholder Relations: Organize an active list of all current sponsors, while collaborating alongside engineering sub-teams to identify new sponsorship opportunities, and keeping precise records of all outreach activities    \n' 

                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'We are looking for members who have a sincere desire to learn about and support the business-based aspect of MAC Formula Electric. You could be a suitable candidate for the Business sub-team if you have strong verbal and written interpersonal skills, particularly with regards to teamwork and communication. Due to the nature of the field, any prior marketing or sponsorship experience would be considered an asset. ' 
                //         },
                //     ],
                //     openDate: 'August 28, 2024 at 12:00 AM EST',
                //     closeDate: 'September 4, 2024 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/ESMELqS80P',
                // },
                // {
                //     type: ['software'],
                //     name: 'Controls',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'The Controls team is responsible for developing, testing, integrating, and continuously improving our vehicle’s embedded control system. Our control system monitors and controls various mechanical and electrical aspects of the vehicle such as the bootup/shutdown sequence, ready to drive checks, motor torque outputs, etc. ' 
                                
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'On the controls team, members will choose projects to their liking and oversee the lifecycle from start to finish, becoming a subject matter expert. You will contribute to various tasks and will collaborate with other members regarding design decisions. You will be expected to document your project and all the learnings/failures you experienced. \n' 
                //                 + ' \nSome examples of these projects are a regenerative braking system, vehicle dynamic algorithms, data logging and telemetry, and a traction control system.  \n'
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'This sub-team is looking for eager, hardworking individuals with great communication skills. The ideal candidate would be very reliable, accountable, and willing to put time and effort into the team. An understanding of CAN communication protocol and previous experience using MATLAB and Simulink is preferred but not required. ' 
                //         },
                //     ],
                //     openDate: 'August 28, 2024 at 12:00 AM EST',
                //     closeDate: 'September 4, 2024 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/ZRUPnvcMxn',
                // },
                // {
                //     type: ['powertrain'],
                //     name: 'Drivetrain',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'The Drivetrain sub team is responsible for bringing power from the motors to the wheels. We design custom in-hub gearboxes, as well as related components across the vehicle. We design as much as we can in-house, including the gears themselves.  ' 
                                
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'We are looking for members to perform CAD tasks, FEA, and hands on testing. The role(s) would be a roughly 80/20 split between computer work and shop work. This is a great opportunity to refine your CAD skills and gain in-depth knowledge on high performance gear systems. ' 
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'We are looking for students with significant CAD experience, ideally in Solidworks. In addition to this, we highly value hands-on experience, even if it is not in the automotive industry. Above all, we are looking for passionate students who are eager to learn.  ' 
                //         },
                //     ],
                //     openDate: 'August 28, 2024 at 12:00 AM EST',
                //     closeDate: 'September 4, 2024 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/afHHD0WxBr',
                // },
                // {
                //     type: ['electrical'],
                //     name: 'LV/HV Electronics',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'We at MAC Formula Electric are looking for passionate students that want to get involved in hardware engineering and design. We are looking for students that have a passion for learning, exploring, and taking on projects from start to finish. \n' + 
                //                 '\n' + 
                //                 'The Electrical sub-team is tasked with designing, manufacturing, and testing the electrical system for MAC Formula Electric. On this team, you can expect to be exposed to a vast array of electronic systems including vehicle communication, safety circuitry, high voltage control circuitry, power supply circuits, and much more. Our team works closely with the Mechanical and Software sub-teams to create a cohesive design for each competition season. \n' 
                                
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'On the Electrical sub-team, you will be involved in helping get the electrical system for MAC EV4 up and running for this season. Your tasks will include assisting with start to finish manufacturing of custom designed PCB boards as well as testing and validating electrical sub-systems through SPICE simulations. You will gain experience using an industry standard PCB design software, Altium Designer, as well as have the opportunity to be hands-on, using laboratory equipment such as oscilloscopes, DC power supplies, DC loads, and multimeters to help test the electrical architecture. You will also aid in the manufacturing of the vehicles\' wiring harness, which connects all electrical components to the various endpoints, in addition to documentation and inventory management. ' 
                //         },
                //         {
                //             head: 'Key Qualifications:',
                //             body:
                //                 '   - Eagerness to learn new concepts quickly and apply them to help develop the team. \n'
                //                 + '\n' + 
                //                 '   - Love to work and collaborate with colleagues as well as other sub-teams on MACFE.  \n'
                //                 + '\n' + 
                //                 '   - Genuine curiosity to solve problems, struggle with those problems, and keep trying.  \n'
                //                 + '\n' + 
                //                 '   - Excellent verbal and written interpersonal skills.  \n'
                //                 + '\n' + 
                //                 '   - Willing to get your hands dirty and get hands on with the car.  \n'

                //         },
                //         {
                //             head: '*Preferred Qualifications:',
                //             body:
                //                 '   - Prior internship(s), personal project experience, TA/work experience. \n'
                //                 + '\n' + 
                //                 '   - Strong electrical engineering fundamentals.  \n'
                //                 + '\n' + 
                //                 '   - Design experience with CAD software such as CATIA, SOLIDWORKS, Altium, or Eagle.  \n'
                //                 + '\n' + 
                //                 '   - Proficiency in reading schematics.  \n'
                //                 + '\n' + 
                //                 '   - Experience using power supplies, multimeters, oscilloscopes.  \n'
                //                 + '\n' + 
                //                 '   - Coding experience in C or Python.  \n'
                //                 + '\n' + 
                //                 '*It is not necessary to have any of the preferred qualifications to be considered for this position.  \n'

                //         },
                //     ],
                //     openDate: 'August 28, 2024 at 12:00 AM EST',
                //     closeDate: 'September 4, 2024 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/skimHNreej',
                // },
                // {
                //     type: ['electrical'],
                //     name: 'Battery Integration',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'We at MAC Formula Electric are looking for passionate students that want to get involved in hardware engineering and design. We are looking for students that have a passion for learning, exploring, and taking on projects from start to finish. \n' + 
                //                 '\n' + 
                //                 'The electrical sub-team is tasked with designing, manufacturing, and testing the electrical system for MAC Formula Electric. On this team, you can expect to be exposed to a vast array of electronic systems including vehicle communication, safety circuitry, high voltage control circuitry, power supply circuits, and much more. Our team works closely with the mechanical and software sub-teams to create a cohesive design for each competition season. \n' 
                                
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'On the Electrical sub-team, you will be involved in helping get the electrical system for MAC EV4 up and running for this season. Your tasks will include assisting with start to finish manufacturing of custom designed PCB boards as well as testing and validating electrical sub-systems through SPICE simulations. You will gain experience using an industry standard PCB design software, Altium Designer, as well as have the opportunity to be hands-on, using laboratory equipment such as oscilloscopes, DC power supplies, DC loads, and multimeters to help test the electrical architecture. You will also aid in the manufacturing of the vehicles\' wiring harness, which connects all electrical components to the various endpoints, in addition to documentation and inventory management. ' 
                //         },
                //         {
                //             head: 'Key Qualifications:',
                //             body:
                //                 '   - Eagerness to learn new concepts quickly and apply them to help develop the team. \n'
                //                 + '\n' + 
                //                 '   - Love to work and collaborate with colleagues as well as other sub-teams on MACFE.  \n'
                //                 + '\n' + 
                //                 '   - Genuine curiosity to solve problems, struggle with those problems, and keep trying.  \n'
                //                 + '\n' + 
                //                 '   - Excellent verbal and written interpersonal skills.  \n'
                //                 + '\n' + 
                //                 '   - Willing to get your hands dirty and get hands on with the car.  \n'

                //         },
                //         {
                //             head: '*Preferred Qualifications:',
                //             body:
                //                 '   - Prior internship(s), personal project experience, TA/work experience. \n'
                //                 + '\n' + 
                //                 '   - Strong electrical engineering fundamentals.  \n'
                //                 + '\n' + 
                //                 '   - Design experience with CAD software such as CATIA, SOLIDWORKS, Altium, or Eagle.  \n'
                //                 + '\n' + 
                //                 '   - Proficiency in reading schematics.  \n'
                //                 + '\n' + 
                //                 '   - Experience using power supplies, multimeters, oscilloscopes.  \n'
                //                 + '\n' + 
                //                 '   - Coding experience in C or Python.  \n'
                //                 + '\n' + 
                //                 '*It is not necessary to have any of the preferred qualifications to be considered for this position.  \n'

                //         },
                //     ],
                //     openDate: 'August 28, 2024 at 12:00 AM EST',
                //     closeDate: 'September 4, 2024 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.microsoft.com/r/us3aDjZMKX',
                // },
                // {
                //     type: ['software'],
                //     name: 'Firmware and Software',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'The Firmware team is responsible for programming the devices which run inside the car. This involves reading from sensors, setting output signals and communicating with other devices using CAN buses. Last year, we learned FreeRTOS, C++ abstraction principles, and CMake to develop a real-time firmware system with complete platform abstraction, enabling multimodal testing and debugging. One such testing mode is our new Software-In-the-Loop (SIL) test server which is written in Go.' 
                //                 + '\n' + 
                //                 'However, only a few firmware members are actually writing this code. Most of our projects revolve around making it easier to develop, debug, test, and visualize the firmware. These projects are all very different and use programming languages like Go, Python, CMake and bash, among many others. '
                //                 + '\n' + 
                //                 'If you are interested in Simulink controls model, see the “Controls” team application. '
                //         },
                //         {
                //             head: 'Role 1: Data Acquisition & Processing',
                //             body:
                //                 'A new project this year is data acquisition and processing. In this role, you will work with other members to design and implement a logging system to record live data from our CAN bus.' 
                //                 + '\n' + 
                //                 'You will then collect and process vehicle data to help the Controls team tune their model. This involves plotting interesting data points and communicating your findings with other team members. '
                //                 + '\n' + 
                //                 'Required Skills: Python, especially numpy and matplotlib for data processing.'
                //                 + '\n' +
                //                 'Preferred Skills: CAN, bash, networking (ex. sockets, WiFi).'
                //         },
                //         {
                //             head: 'Role 2: RTOS Lead',
                //             body:
                //                 'Our vehicle firmware manages a lot of time-critical tasks. We use an RTOS (real-time operating system) to organize these tasks and ensure they meet their deadlines.' 
                //                 + '\n' + 
                //                 'You will lead the design of our firmware RTOS system. This involves writing requirements, prototyping code solutions, and collaboratively communicating your design with other team members.'
                //                 + '\n' + 
                //                 'A qualified member will have a strong background using an RTOS. Knowledge of C++ is also required. Experience with stm32 microcontrollers / STM32CubeMX is a bonus.'
                //         },
                //         {
                //             head: 'Role 3: General Member',
                //             body:
                //                 'If you aren’t interested in any of the other roles, apply to be a general member! You will be assigned tasks on a per-project basis and can specialize later. ' 
                //                 + '\n' + 
                //                 'Required skill: Coding experience in any language and a strong work ethic.'
                //                 + '\n' + 
                //                 'In your application, mention if you have experience with C++, Python, Makefile, CMake, Go, Git, gRPC, FreeRTOS, JavaScript, stm32, and any other software skills.'
                //         },
                //     ],
                //     openDate: 'August 28, 2024 at 12:00 AM EST',
                //     closeDate: 'September 4, 2024 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/iQF8KuWBAZ',
                // },
                // {
                //     type: ['mechanical'],
                //     name: 'Human Factors and Ergonomics',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'On the Human Factors and Ergonomics (HFE) sub-team, we are responsible for all components the driver will directly interact with, some of which include the seat, headrest, steering wheel, and driver equipment. We aim to achieve optimum driver focus and control of the car by progressing through the entire design process ranging from research and analysis through to testing.' 
                                
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'Members will rotate through various tasks in design which include performing the required research and analyses through SOLIDWORKS or hand calculations, manufacturing the designed components, and finally testing these components. All designed components must follow the rules and guidelines of the competition. As a member of HFE, you will also be communicating with members in sub-teams aside from our own to ensure integration of our subsystem is seamless with the rest of the car.' 
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'This sub-team is looking for individuals passionate about learning and exploring new concepts. We are looking for members who are reliable, show exceptional work ethic, and show great dedication to our team’s success. While CAD experience is not required, it is a great benefit to this position.' 
                //         },
                //     ],
                //     openDate: 'August 28, 2024 at 12:00 AM EST',
                //     closeDate: 'September 4, 2024 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/zWDvRCNvyj',
                // },
                // {
                //     type: ['mechanical'],
                //     name: 'Manufacturing',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'The Manufacturing sub-team is responsible for all in-house composites and machining tasks. The sub-team also drafts and interprets technical documentations such as engineering drawings to ensure parts meet specifications while not failing under their specific loading conditions and adhering to precise tolerances.' 
                                
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'We are mainly seeking lower-year students for a variety of manufacturing tasks. Composites related tasks include the monocoque, airfoils, nose cone, firewall, and any other carbon fibre, Kevlar, and fibreglass parts. Machining related tasks include components manufactured using processes such as waterjet cutting, lathing, milling, and welding.' 
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'The most important qualities that you can bring to the team are your time-management skills and dedication. Technical work can only be taught effectively if you are able to consistently show up to learn and work, which leads to knowledge retention. Any CAD or workshop experience is desired but not required.' 
                //         },
                //     ],
                //     openDate: 'August 28, 2024 at 12:00 AM EST',
                //     closeDate: 'September 4, 2024 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/LDtiN9h9Zr',
                // },
                // {
                //     type: ['mechanical'],
                //     name: 'Suspension and Steering',
                //     description: [
                //         {
                //             head: 'About the Sub-Team:',
                //             body:
                //                 'The Suspension and Steering team is responsible for the design, analysis, testing, and validation of a working suspension and steering package for the Mac EV6. We use vehicle models to generate design criteria, then design and manufacture the mechanical parts to achieve our vehicle handling goals.' 
                                
                //         },
                //         {
                //             head: 'About the Role(s):',
                //             body:
                //                 'One role will be a traditional mechanical design role, where you will own (either by yourself or in a group) one part or subsystem. This will involve generating the basic design requirements for this part/subsystem, creating and iterating through designs, producing designs in Solidworks, performing FEA analysis, and finally manufacturing the part/subsystem. You will work closely with the subteam lead to determine the requirements and appropriate design considerations and iterate through designs.'
                //                 + '\n\n' + 
                //                 'The other role will be vehicle dynamics-focused, where you will use Excel and Matlab calculators to perform basic dynamics calculations, build and analyse vehicle handling models using CarSim and/or Simulink, and use this data to make recommendations for the suspension design as well as other vehicle systems.'
                //                 + '\n\n' + 
                //                 'Both roles may have the opportunity to participate in vehicle testing. We are impartial to upper or lower year students. '
                //         },
                //         {
                //             head: 'Your Background:',
                //             body:
                //                 'We are looking for members who are hardworking, can collaborate with others, and show enthusiasm and passion for learning. We require members who are reliable, can be counted on to complete work, and willing to contribute time to the team. Previous CAD/Matlab experience and vehicle dynamics knowledge is a bonus, but not required.' 
                //         },
                //     ],
                //     openDate: 'August 28, 2024 at 12:00 AM EST',
                //     closeDate: 'September 4, 2024 at 11:59 PM EST',
                //     applyLink:
                //         'https://forms.office.com/r/7VufBEd63D',
                // },
            ],
        };
    }

    updatePositions = (newPositions) => {
        this.setState({ positionsListed: newPositions });
    };

    updatePositionsVisible = (newPositions) => {
        this.setState({ positionsListed: newPositions });
    };

    render() {
        const visiblePosition = this.state.positions.filter(
            (position) =>
                position.type.includes(this.state.positionsListed) ||
                this.state.positionsListed === 'all'
        );

        const positions = (
            <div className="positions-section-container">
                <div className="positions-container">
                    {visiblePosition.map((position) => (
                        <div
                            className={`position-container ${
                                position.applyLink === '#' ? 'closed' : 'open'
                            }`}
                        >
                            <div
                                className="position"
                                onClick={this.props.updatePositionPage.bind(
                                    this,
                                    position
                                )}
                            >
                                <div className="position-title-container">
                                    <h1 className="position-title">
                                        {position.name}
                                    </h1>
                                </div>
                                <div className="position-openings-container">
                                    <h2 className="position-opening-title">
                                        Opens:{' '}
                                    </h2>
                                    <h1 className="position-openings">
                                        {position.openDate}
                                    </h1>
                                </div>
                                <div className="position-openings-container">
                                    <h2 className="position-opening-title">
                                        Closes:{' '}
                                    </h2>
                                    <h1 className="position-openings">
                                        {position.closeDate}
                                    </h1>
                                </div>
                                <div className="position-description-container">
                                    <p className="position-description">
                                        {' '}
                                        {position.description[0].body.length >
                                        300
                                            ? position.description[0].body
                                                  .split('')
                                                  .slice(0, 300)
                                                  .join('') + '...'
                                            : position.description[0].body}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );

        return (
            <div>
                {/* <PositionSelector updatePosition={this.updatePositions} positions={this.state.positionsListed}/> */}
                {positions}
                
            </div>
        );
    }
}

export default Positions;
