import React, { Component } from 'react';
import './WhyJoin.css';

export class WhyJoin extends Component {
    render() {
        return (
            <div className="why-join-container">
                <div className="showbox">
                    {/* <div className="why-join-intro-paragraph-container">
                        <p className="why-join-intro-paragraph">
                            Our recruitment starts soon. Please visit our <a href="https://www.instagram.com/macformulaelectric/?hl=en">Instagram</a> (@macformulaelectric) for more information.
                        </p>
                    </div> */}
                    <div className="why-join-intro-paragraph-container">
                        <p className="why-join-intro-paragraph">
                            <center>
                            MAC Formula Electric is seeking motivated and passionate students who are looking for an opportunity
                            to learn and apply themselves to real-world engineering problems.
                            We are tackling the coming season through three core pillars of teamwide culture: Learning, Teamwork,
                            and Excellence. Our growth as a team comes from what we learn and how we learn it. Our successes and
                            failures come down to how we work together as a team to tackle the challenges ahead. Our progress as
                            a team depends on our commitment to the goal of besting our competition.
                            </center>
                        </p>
                    </div>
                    <div className="why-join-intro-paragraph-container">
                        <p className="why-join-intro-paragraph">
                           <center> Members of our team can expect to work with technologies, processes, and manufacturing
                            techniques that are seldom found in a classroom environment. By joining our team, students
                            can hone their technical and interpersonal skills and apply them throughout co-op placements,
                            full-time employment, and even research environments.
                           </center>
                            <br></br>
                            <br></br>
                            <center>In the postings, the following definitions will be used:</center>
                            <br></br>
                            <center>
                            <ul>
                                Lower-year: students who are in their first or second year
                                <br></br>
                                Upper-year: students who are in their third year or above (also applies to graduate students)
                            </ul>
                            </center>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default WhyJoin;
